export function align() {
  let str = to_string(...arguments)
  let lines = str.split('\n')
  while (/^\s*$/.test(lines[0])) {
    lines.shift()
  }
  while (/^\s*$/.test(lines[lines.length - 1])) {
    lines.pop()
  }
  let min_spaces = Infinity
  for (let line of lines) {
    let match = line.match(/^\s*/)
    min_spaces = Math.min(min_spaces, match[0].length)
  }
  return lines.map(line => line.slice(min_spaces)).join('\n')
}

function to_string(parts, ...data) {
  if (!Array.isArray(parts)) {
    return parts
  }
  let s = parts[0];
  for (let i = 1; i < parts.length; i++) {
    s += data[i];
    s += parts[i];
  }
  return s;
}
