import React from 'react'
import Layout from '../Layout.js'
import SplashHeader from '../SplashHeader.js'
import {capitalize} from 'lodash'
import {align} from '../util/string.js'
import classnames from 'classnames'

export default () => {
  return (
    <Layout page="dna">
      <SplashHeader height="30vh"
        image="https://images.unsplash.com/photo-1484417894907-623942c8ee29?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1189&q=80"
        alignImage="bottom">
        <div className="splash-text">
          <h1 className="display-5 mb-4">
            <strong>How we work</strong>
          </h1>
        </div>
      </SplashHeader>
      <div className="container d-flex">
        <div className="dna">
          <Section id="responsibility">
            <Rule id="check-your-work" title="Check your own work">
              <p>
                No one should need to verify that your changes are correct and bug
                free. Take the time to test your work before you commit it, and
                verify nothing broke once it's released.
              </p>
            </Rule>
            <Rule id="track-tasks" title="Track your own tasks">
              <p>
                No one should need to remind you of a task you forgot to handle.
                Find a way to track it so that it doesn't get dropped
                (pen and notebook works great).
              </p>
            </Rule>
            <Rule id="decide" title="Make decisions">
              <p>
                If you're not sure about something, ask for clarification.
                If you can't get clarification, make a decision.
                Be prepared to explain the decision you made.
              </p>
            </Rule>
          </Section>
          <Section id="code">
            <Rule id="merge-to-master"
              title="Merge to master by the end of the work day">
              <p>
                Code that's not on master is dead weight.
                The longer your code is in a branch, the more time the codebase
                has to become incompatible with your changes.
              </p>
              <p>
                If you have a large task that will take a couple of days to finish,
                you need to break it into smaller tasks so you can merge to master
                every day.
              </p>
            </Rule>
            <Rule id="newer-rewrite" title="Never rewrite a project from scratch">
              <p>
                Rewriting a project as almost always a bad idea.
                The product will be stalled while you try to reach feature parity
                with the old version (not to mention all the bugs your new version
                will have that the old version already fixed).
              </p>
              <p>
                Or if you try to create a new version in parallel with changing
                the old version, you're now trying to catch a moving target and
                the rebuild is now a parasite that's slowing the progress of the
                <b>product</b>{' '} from getting better.
              </p>
              <a href="https://www.joelonsoftware.com/2000/04/06/things-you-should-never-do-part-i/">Read more</a>
            </Rule>
            <Rule id="refactor-gracefully" title="Refactor gracefully">
              <p>
                Instead of attempting full rebuilds, do targeted surgery on the
                existing system.
                All refactoring should happen in small stages that go live quickly.
                Each change should have a safe migration path.
              </p>
              <p>
                When you change a function or a data format or an endpoint,
                search the entire codebase to see if it's used anywhere else.
                Ensure your changes never break existing functionality.
                (<code>grep</code> is your friend).
              </p>
            </Rule>
            <Rule id="refactor-constantly" title="Refactor constantly">
              <p>
                Generally leave code 20% better than you found it.
                Don't go crazy refactoring, just fix bad code when you work on it.
              </p>
            </Rule>
            <Rule id="imitate" title="Imitate code style">
              <p>
                Follow the code style around you. It might be hard for you to
                read and write code that's not the "right style", but it's even
                harder to follow code that's written in 3 different styles.<br/>
                Consistency trumps beauty.
              </p>
            </Rule>
            <Rule id="clarity" title="Pursue clarity">
              <p>
                Clear code is much better than short code.
                Aim for concise code, but above all aim for obvious code.
              </p>
              <p>
                Avoid clever tricks at all costs. The extra cognitive load is
                almost never worth the 2 lines you saved.
              </p>
            </Rule>
            <Rule id="comments" title="Only add useful comments">
              <p>
                Add lots of comments in the form{' '}
                <code>// TODO {'<name>'}: blah blah</code> to document what needs
                to be fixed and improved in the code.
              </p>
              <p>
                Add thorough comments when documenting a weird edge case
                or some unexpected behaviour in an external system or a general
                description of how a library should be used.
              </p>
              <p>
                If the comment doesn't add extra information, delete it.
                A comment that just repeats what the code says is useless:
              </p>
              <Example bad>
                {align`
                  // return the sum of the hours
                  return hours.reduce((sum, i) => i+sum, 0)
                `}
              </Example>
            </Rule>
          </Section>
          <Section id="communication">
            <Rule id="respond-quickly" title="Respond quickly">
              <p>
                Respond quickly to prevent blocking someone else's work.
                If you need to research something first, or something will take
                time to handle, say so in your reply.
              </p>
            </Rule>
            <Rule id="archive-quickly" title="Archive quickly">
              <p>
                Once you read an email, archive it.
                If you need to complete a task as a result of the email, use
                something else to schedule it. Keep your inbox clean.
              </p>
            </Rule>
            <Rule id="threads" title="Keep email threads separate">
              <p>
                Don't mix topics in an email thread.
                If you have an extra question, send an extra email with a new,
                clear subject.
              </p>
            </Rule>
            <Rule id="threads" title="Don't reply inline">
              <p>
                Replying inline becomes almost impossible to follow once it gets
                more than 1 level deep.<br/>
                If you want to respond to something specific, quote it in your
                reply and leave the original email untouched.
              </p>
              <Example good>
                {align`
                  > How much does the new library increase page weight?

                  15kb
                `}
              </Example>
            </Rule>
            <Rule id="email-search" title="Optimize for search">
              <p>
                Load your subject lines with key words so you can easily search
                for it later.
              </p>
            </Rule>
            <Rule id="call" title="Call">
              <p>
                If a phone call will get results faster, then call.
                If you decide on anything in the call, sumarize it in an email to
                keep a record of it.
              </p>
            </Rule>
          </Section>
        </div>
      </div>
    </Layout>
  )
}

function Section({id, children}) {
  return (
    <div>
      <h2 className="mt-4 mb-3" id={id}>{capitalize(id)}</h2>
      {children}
    </div>
  )
}

function Rule({id, title, children}) {
  return (
    <div className="mb-3">
      <h3 id={id} className="dna-rule__title">{title}</h3>
      {children}
    </div>
  )
}

function Example({bad, good, children}) {
  return (
    <p className={classnames('dna-rule__example', {bad, good})}>
      {align(children)}
    </p>
  )
}
